<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="8" md="4" lg="4">
                <v-select v-model="OrganizationId"
                          :items="Organization"
                          label="สาขา"
                          placeholder="สาขา"
                          dense
                          outlined
                          required></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="2" lg="2">
                <v-btn block @click="Insert()">เพิ่ม</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
                <v-card>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center" width="200">รหัสสาขา</th>
                                    <th class="text-center">สาขา</th>
                                    <th class="text-left" width="50"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in UserDetail" :key="item.UserDetailId">
                                    <td>{{item.Organization.OrganizationCode}}</td>
                                    <td>{{item.Organization.OrganizationName}}</td>
                                    <td>
                                        <v-btn fab dark small color="red" @click="Delete(item)">
                                            <v-icon dark>fa fa-trash-alt</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import Axios from "axios";

    export default {
        data() {
            return {
                UserId: "",
                UserDetail: [],
                OrganizationId: "",
                Organization: []
            };
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$store.state.User.UserLevel == "M") {
                    this.Select();
                    this.SelectOrg();
                    if (this.$route.query.UserId != null) {
                        this.UserId = this.$route.query.UserId;
                        this.Select(this.UserId);
                    } else {
                        this.$router.push("User");
                    }
                } else {
                    this.$router.push("/Member");
                }
            });
        },
        methods: {
            Select(v) {
                Axios.get("/Api/V1/Select/Select/UserDetail?UserId=" + v).then(res => {
                    if (res.status == 200) {
                        this.UserDetail = res.data;
                    }
                });
            },
            Insert() {
                if (this.OrganizationId == "") return;
                Axios.post("/Api/V1/Insert/Insert/UserDetail", {
                    UserId: this.UserId,
                    OrganizationId: this.OrganizationId
                }).then(res => {
                    if (res.status == 200) {
                        this.Select(this.UserId);
                    }
                });
            },
            Delete(v) {
                Axios.delete("/Api/V1/Delete/Delete/UserDetail?UserDetailId=" + v.UserDetailId).then(res => {
                    if (res.status == 200) {
                        this.Select(this.UserId);
                    }
                });
            },
            SelectOrg() {
                Axios.get("/Api/V1/Select/Select/Organization").then(res => {
                    if (res.status == 200) {
                        this.Organization = res.data;
                    }
                });
            }
        }
    };
</script>
